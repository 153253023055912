
<template>
    <b-field label="Trabajador" label-position="on-border">
        <b-autocomplete
            icon="magnify"
            placeholder="Buscar por DNI, Nombre o Apellido"
            :value="text"
            :clearable="true"
            :loading="isLoading"
            :data="data"
            @typing="getAsyncData"
            @select="selectOption"
        >
            <template slot-scope="props">
                <span class="has-text-weight-light"
                    >{{ props.option.apellido_paterno }}
                    {{ props.option.apellido_materno }}
                    {{ props.option.nombre }}</span
                ><br />
                <div class="is-flex is-align-content-center">
                    <img
                        :src="props.option.pais_ruta_imagen"
                        class="image is-16x16"
                    />
                    <small class="ml-1">{{ props.option.dni }}</small>
                </div>
            </template>
            <template #empty>No se encontraton resultados</template>
        </b-autocomplete>
    </b-field>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
    model: {
        prop: 'trabajadorId',
        event: 'trabajadorIdChange',
    },
    data() {
        return {
            isLoading: false,
            selected: null,
            data: [],
        };
    },
    computed: {
        trabajadorIdLocal: {
            get: function () {
                return this.trabajadorId;
            },
            set: function (value) {
                this.$emit('trabajadorIdChange', value);
                this.$emit('input');
            },
        },
        text() {
            return this.selected
                ? ` ${this.selected.dni} - ${this.selected.nombre} ${this.selected.apellido_paterno} ${this.selected.apellido_materno}`
                : '';
        },
    },
    methods: {
        getAsyncData: debounce(async function (name) {
            if (!name.length) {
                this.data = [];
                return;
            }

            this.fetchTrabajador(name);
        }, 250),
        async fetchTrabajador(text) {
            this.isLoading = true;
            try {
                const res = await this.$store.dispatch(
                    'empresas/getEmpleados',
                    { query: text, per_page: 10 }
                );
                this.data = res.data;
            } catch (e) {
                this.data = [];
            } finally {
                this.isLoading = false;
            }
        },
        selectOption(option) {
            this.selected = option;
            this.trabajadorIdLocal = option?.id || '';
        },
    },
};
</script>

<style>
</style>
